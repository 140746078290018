var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"YRRB51T7byLcs4Mf9rBt9"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/dnse-recruitment";

/* eslint-disable prefer-destructuring */
import { init } from '@sentry/nextjs';

import { sentryClientConfig } from './src/services/sentry/sentry.client';

init(sentryClientConfig);
