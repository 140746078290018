import { createContext, useContext } from 'react';

export enum ApplyStep {
  ViewJobDetails = 0,
  UploadCV = 1,
  SubmitSuccessfully = 2
}

export type AuthContextState = {
  step: ApplyStep;
  setStep: (step: ApplyStep) => void;
  resume: FileList | null;
  setResume: (resume: FileList | null) => void;
  appliedJobId: string | null;
  setAppliedJobId: (appliedJob: string | null) => void;
};

export const ApplyContext = createContext<AuthContextState>({
  step: ApplyStep.ViewJobDetails,
  setStep: () => {},
  resume: null,
  setResume: () => {},
  appliedJobId: null,
  setAppliedJobId: () => {}
});

export const useApplyContext = () => {
  const context = useContext(ApplyContext);
  if (!context) {
    throw new Error(
      'useApplyContext must be inside ApplyContext with state value'
    );
  }
  return context;
};
