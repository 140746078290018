import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

/**
 * Just for public runtime configs
 */
export const AppEnv = {
  HOME_URL: publicRuntimeConfig.HOME_URL as string,
  HOST: publicRuntimeConfig.HOST as string,
  NODE_ENV: publicRuntimeConfig.NODE_ENV as 'development' | 'production',
  ENV: publicRuntimeConfig.ENV as 'development' | 'production' | 'uat',
  REVALIDATE: +publicRuntimeConfig.REVALIDATE as number,
  DIRECTUS_URL: publicRuntimeConfig.DIRECTUS_URL as string,
  DIRECTUS_FOLDER_ID: publicRuntimeConfig.DIRECTUS_FOLDER_ID as string,
  CDN_PREFIX: publicRuntimeConfig.CDN_PREFIX as string,
  CDN_FOLDER: publicRuntimeConfig.CDN_FOLDER as string,

  SENTRY_DSN: publicRuntimeConfig.SENTRY_DSN as string
};
