import { PropsWithChildren, useState } from 'react';

import { ApplyContext, ApplyStep } from './apply.context';


export const ApplyProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [step, setStep] = useState<ApplyStep>(ApplyStep.ViewJobDetails);
  const [resume, setResume] = useState<FileList | null>(null);
  const [appliedJobId, setAppliedJobId] = useState<string | null>(null);
  return (
    <ApplyContext.Provider
      value={{
        step,
        resume,
        appliedJobId,
        setAppliedJobId,
        setResume,
        setStep
      }}
    >
      {children}
    </ApplyContext.Provider>
  );
};
