import { ApolloClient, InMemoryCache } from '@apollo/client';

import { AppEnv } from '../../utils/accessEnviroment';


const { DIRECTUS_URL } = AppEnv;

export const apolloClientCache = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `${DIRECTUS_URL}/graphql`,
  headers: {
    authorization: `Bearer ${process.env.DIRECTUS_TOKEN}`
  }
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `${DIRECTUS_URL}/graphql`,
  headers: {
    authorization: `Bearer ${process.env.DIRECTUS_TOKEN}`
  },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});
